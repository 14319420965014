<template>
<div class="pt-1 px-2 text-sm pb-8">
    <div class="grid grid-cols-12 lg:grid-cols-9 gap-2 items-center dark:text-white"> 
        <h3 class="col-start-2 col-span-12 text-gray-700">{{title}}</h3>
        <div class="col-span-1 flex justify-end px-2">
            <p>{{min}}</p>
        </div>
        <vue-slider 
            :modelValue="value"
            @update:modelValue="$emit('onChange', $event)"
            tooltip="always"
            :min="min"
            :max="max"
            :enable-cross="true"
            :lazy="true"
            :tooltip-placement="'bottom'"
            class="col-span-9"
        />
            <div class="col-span-2 lg:col-span-2 flex">
                <p class="w-full ">{{max}} {{backTitle}}</p>
            </div>
    </div>
</div>
</template>

<script>

// import { ref, watch } from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
    name: "RangeSliderInput",
    components: { VueSlider },
    props: {
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        value: {
            type: [Number, Array],
            default: 0
        },
        title: {
            type: String,
            default: ""
        },
        backTitle: {
            type: String,
            default: null
        }
    },
};
</script>

<style>
    .vue-slider-process  {
        background-color: gray;
    }
    .vue-slider-dot-tooltip-inner {
        background-color: gray;
        border-color: gray;
    }
</style>
