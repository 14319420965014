<template>
    <div @click.stop class=" w-full relative">
        <div class="flex items-center" v-if="heading">
          <p class="text-sm font-medium text-gray-700 dark:text-white mb-1 pr-1">{{heading}}</p>
            <span class="" title="Feel free to add a new option!"><BaseToolTip v-if="tooltip" :text=toolText> </BaseToolTip></span>
        </div>
        <!--       Top bar -->
        <!--    Label and Clear Filters Button.
                NOTE: The transparent border around the div
                is there to align the icons
        -->
        <div v-if="label" class="flex justify-between eye-input rounded-md border-transparent text-sm">
            <label class="text-sm text-gray-500 dark:text-white">{{ label }}</label>
            <font-awesome-icon
                icon="fa-solid fa-xmark"
                class="cursor-pointer place-self-center text-gray-500 px-2 hover:text-gray-700 dark:text-gray-300 dark-hover:text-gray-100"
                fixed-width
                size="xs"
                @click="clearFilters(); closeDropdown()" />
        </div>
        <div
            @click="onToggle()"
            class="w-full flex eye-input shadow-sm border-gray-300 rounded-md justify-between min-h-10">
            <!--            Selected values -->
            <div class="flex flex-wrap-reverse">
                <div v-if="firstTenItems.length === 0"
                     class="flex items-center"
                >
                    <p class="text-sm px-2 text-gray-600"></p>
                </div>
                <div v-for="val, idx in firstTenItems"
                     :key="idx+10"
                     class="bg-gray-200 text-gray-800 rounded my-1 mx-1 px-2 gap-2 flex
                     items-center whitespace-normal h-7 text-sm"
                >
                    <p class="line-clamp-1">
                     {{ val }}
                    </p>
                    <font-awesome-icon
                        icon="fa-solid fa-xmark"
                        class="cursor-pointer text-gray-600"
                        @click.stop="onRemoveItem(idx)"
                    />
                </div>
            </div>
            <!--            Close icon -->
            <div
                class="flex items-center justify-end text-gray-500 transition"
                :class="[active ? 'transform rotate-180' : '']"
            >
                <font-awesome-icon icon="fa-solid fa-chevron-down"
                    class="px-2 text-gray-500 dark:text-gray-300 dark-hover:text-gray-100"
                    fixed-width
                    size="xs"
                />
            </div>
        </div>

        <!--        Item overflow indicator-->
        <div class="flex px-2">
            <p v-if="modelValue.length > 10"
               class="text-sm font-semibold text-gray-600"
            > and {{ modelValue.length - 10 }} more </p>
        </div>

        <!--        Expandable section-->
        <div
            class="w-full shadow-xl bg-white overflow-auto no-scrollbar max-h-96 dark:bg-primaryDark dark:text-white absolute z-20 border-2 rounded-md text-sm"
            v-if="active"
            @keydown.down.prevent="arrowScrollDown"
            @keydown.up.prevent="arrowScrollUp"
        >
            <!-- Search -->
            <input
                @keydown.enter="onAddItem(filteredOptions[focusedIdx]?.key); onToggle()"
                ref="searchField"
                type="text"
                class="flex p-4 w-full dark:bg-primaryDark dark:text-white rounded h-7  border-transparent focus:border-none focus:ring-0 text-sm sticky top-0"
                v-model="searchTerm"
                :placeholder="searchPlaceholder"/>
             <!-- NOTE: The transparent border in the div below
                        is to align it with the search bar above -->
            <div
                v-for="opt, idx in filteredOptions"
                :key="idx"
                :ref="el => {menuItems[idx] = el}"
                class="border border-transparent py-1 pl-4 pr-3 text-gray-500 font-normal dark:text-gray-300 rounded"
                :class="[modelValue.includes(opt.key) && idx == focusedIdx ? 'bg-gray-200 dark:bg-gray-800' :
                        modelValue.includes(opt.key) ? 'hover:bg-gray-200 dark:bg-gray-800' :
                        idx === focusedIdx ? 'bg-gray-200 dark:bg-gray-700' : ''] "
                @click.stop="onAddItem(opt.key);"
                @mousemove="focusedIdx=idx"
            >
            <div class="flex justify-between">
                <div class="">
                    {{ opt.label }}
                </div>
                <font-awesome-icon icon="fa-solid fa-check" v-if="modelValue.includes(opt.key)" fixed-width class="  self-center" size="xs"/>
            </div>
            </div>

        </div>
    </div>
</template>

<script>
import {ref, computed, watch, nextTick, onMounted, onBeforeUnmount} from 'vue'

import BaseToolTip from "@/components/widgets/BaseToolTip.vue";

export default {
    name: "Multiselect",
    components: {
        // ChevronDownIcon,
        // XIcon,
        BaseToolTip,
        // CheckIcon
    },
    props: {
        options: {      // All selectable options
            type: Array,
            required: true
        },
        modelValue: {   // Currently selected values
            type: Array,
            required: true
        },
        createActive: { // true if user is able to create new values
            type: Boolean,
            default: false
        },
        heading: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        searchPlaceholder: {
            type: String,
            required: false,
            default: "Search..."
        },
        toolText:String,
        tooltip: {
        type: Boolean,
        default: false
    },
    },
    setup(props, {emit}) {
        const active = ref(false)       // is the option menu expanded
        const searchTerm = ref("")      // user provided value form search or creation
        const searchField = ref(null)   // ref to search field DOM element
        const focusedIdx = ref(0)       // index of the currently focused option
        const menuItems = ref([])       // references to individual option DOM elements, used for scrolling

        // Focus input field automatically
        watch(searchField, (toggleOpen) => {
            if (toggleOpen) {
                searchField.value.focus()
            }
        })

        // Close menu on clicks outside of it
        const onClickOutside = () => {
            active.value = false
        }
        onMounted(() => window.addEventListener("click", onClickOutside))
        onBeforeUnmount(() => window.removeEventListener("click", onClickOutside))

        const isStr = obj => Object.prototype.toString.call(obj) === "[object String]"
        // props.options could either be a list of objects with label and key or just a list of
        // strings, if it is just basic strings, expand it into the other form.
        const optionObjs = computed(() => isStr(props.options?.[0]) ?
            props.options.map(opt => ({ label: opt, key: opt })) :
            props.options)

        // Filter options when user has provided input
        const filteredOptions = computed(() => searchTerm.value === ""
            ? optionObjs.value : optionObjs.value.filter(s => s.label.toLowerCase().includes(searchTerm.value.toLowerCase())))

        // Scroll
        async function scrollToFocusedIndex() {
            await nextTick()
            menuItems.value[focusedIdx.value].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start'
            })
        }

        return {
            active,
            searchTerm,
            menuItems,
            searchField,
            focusedIdx,
            scrollToFocusedIndex,
            filteredOptions,
            firstTenItems: computed(() => props.modelValue.slice(0, 10)),

            onAddItem: (val) => {
                if (val === undefined) { // If value does not exist in current options
                    if (props.createActive)
                        emit("update:modelValue", [...props.modelValue, searchTerm.value])
                } else {
                    if (!props.modelValue.includes(val)) { // Add item
                        emit("update:modelValue", [...props.modelValue, val])
                    } else { // Remove item when it is re-selected
                        emit("update:modelValue", props.modelValue.filter(item => item !== val))
                    }
                }
            },
            onRemoveItem: (idx) => emit("update:modelValue", props.modelValue.filter((item, i) => i !== idx)),
            clearFilters: () => {
                emit("update:modelValue", [])
            },
            onToggle: () => {
                active.value = !active.value
                searchTerm.value = ""
                focusedIdx.value = 0
            },
            closeDropdown: () => {
                active.value = false
                searchTerm.value = ""
                focusedIdx.value = 0
            },
            arrowScrollDown: () => {
                focusedIdx.value
                    = (focusedIdx.value === filteredOptions.value.length - 1 ? focusedIdx.value : focusedIdx.value + 1)
                scrollToFocusedIndex()
            },
            arrowScrollUp: () => {
                focusedIdx.value
                    = (focusedIdx.value === 0 ? focusedIdx.value : focusedIdx.value - 1)
                scrollToFocusedIndex()
            }
        }
    }
}
</script>
