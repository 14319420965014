<template>
  <div class="flex flex-col justify-between">
    <div class="flex gap-1">
      <label class="block text-sm font-medium text-gray-700 dark:text-white ">{{ heading }} <span v-if="requiredIndicator" class="text-red-600 text-sm">*</span></label>
      <BaseToolTip v-if="tooltip" :text=toolText> </BaseToolTip>
    </div>
    <select :value="getDisplayValue(value)" @input="$emit('update:value', getReturnValue($event.target.value));"
      :class="selectClasses" class="block w-full bg-primary border border-gray-300 rounded-md 
              shadow-sm pt-2 px-3 mt-1 focus:outline-none focus:ring-indigo-500 
              focus:border-indigo-500 sm:text-sm dark:bg-primaryDark dark:text-white pr-8" :disabled="disabled">

      <option v-if="placeholder" value="" disabled selected>{{placeholder}}</option>
      <option v-for="(option, idx) in getOptions()" :key="idx">{{ option }}</option>
    </select>
    <div v-for="error in errors" :key="error.$uid">
      <p class="text-red-600 text-sm">{{ error.$message }}</p>
    </div>
  </div>
</template>

<script>
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
export default {
  name: "SelectInput",
  components: { BaseToolTip },
  props: {
    toolText: String,
    heading: { type: String, required: false, default: "" },
    value: [String, Boolean, Number],
    options: [Array, Object],
    errors: Object,
    placeholder: { type: String, required: false, default: "" },
    disabled: {
        type: Boolean,
        default: false
    },
    version: {
      required: false,
      default: "1"
    },
    tooltip: {
      required: false,
      default: ""
    },requiredIndicator:{
       type:Boolean,
       default:false
    },
  },
  computed: {
    selectClasses() {
      if (this.version == '2') return 'mt-1'
      return ''
    }
  },
  setup(props) {
    // Check if options have key-value pairs and handle accordingly
    let optionsHaveLabels = false;
    if (!Array.isArray(props.options)) {
      optionsHaveLabels = true;
    }

    return {
      getOptions() {
        if (optionsHaveLabels) {
          return Object.keys(props.options)
        }
        return props.options
      },
      getReturnValue(key) {
        if (optionsHaveLabels) {
          return props.options[key]
        }
        return key
      },
      getDisplayValue(opt) {
        if (optionsHaveLabels) {
          return Object.keys(props.options).find(key => props.options[key] === opt)
        }
        return opt
      }
    }

  }
}
</script>
